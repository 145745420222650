import AOS from "aos";
import "aos/dist/aos.css";
const Body = () => {
  AOS.init();
    return(
        <>
  
<div className="container-fluid p-5 bg-theme2 text-center">
    <div className="row">
        <div className="offset-2 col-md-8">
            <h1 data-aos="fade-left">What is Lorem Ipsum?</h1>
    <p data-aos="fade-left">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,</p>
        </div>
    </div> 
  </div>

  <div className="container mt-5 services">
    <div className="row text-center">
        <div className="col-md-12 my-3 text-center">
           <h2 data-aos="fade-up" className="title">Services</h2>
           <p data-aos="fade-up" className="subtitle">It has survived not only five centuries, but also the leap into electronic typesetting</p>
        </div>
        </div>
        <div className="row g-4 text-center" data-aos="fade-in" >
        <div className="col-md-4 col-sm-6">
          <div className="card">
          <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/services/1.jpg"} alt="lorem ipsum" />
            <div className="card-body">
              <h5 className="card-title title">IT Services</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" className="btn btn-theme">Talk to Our Expert</a>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
            <div className="card">
            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/services/2.jpg"} alt="lorem ipsum" />
              <div className="card-body">
                <h5 className="card-title title">IT Infrastructure </h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-theme">Talk to Our Expert</a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card">
            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/services/3.jpg"} alt="lorem ipsum" />
              <div className="card-body">
                <h5 className="card-title title">Other Services</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-theme">Talk to Our Expert</a>
              </div>
            </div>
          </div>
      </div>
  </div>
    <div className="container mt-5">
  
      <div className="row text-center">
        <div className="col-lg-4">
           <img className="img-fluid img-circle" src={process.env.PUBLIC_URL + "/assets/img/services/4.jpg"} alt="lorem ipsum" />
          <h2>Heading</h2>
          <p>Some representative placeholder content for the three columns of text below the carousel. This is the first column.</p>
          <p><a className="btn btn-secondary" href="#">View details »</a></p>
        </div>
        <div className="col-lg-4">
        <img className="img-fluid img-circle" src={process.env.PUBLIC_URL + "/assets/img/services/5.jpg"} alt="lorem ipsum" />
  
          <h2>Heading</h2>
          <p>Another exciting bit of representative placeholder content. This time, we've moved on to the second column.</p>
          <p><a className="btn btn-secondary" href="#">View details »</a></p>
        </div>
        <div className="col-lg-4">
        <img className="img-fluid img-circle" src={process.env.PUBLIC_URL + "/assets/img/services/6.jpg"} alt="lorem ipsum" />
  
          <h2>Heading</h2>
          <p>And lastly this, the third column of representative placeholder content.</p>
          <p><a className="btn btn-secondary" href="#">View details »</a></p>
        </div>
      </div>
  
      <hr className="featurette-divider" />
  
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading">First featurette heading. <span className="text-muted">It ll blow your mind.</span></h2>
          <p className="lead">Some great placeholder content for the first featurette here. Imagine some exciting prose here.</p>
        </div>
        <div className="col-md-5">
        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/about/1.jpg"} alt="lorem ipsum" />
  
        </div>
      </div>
  
      <hr className="featurette-divider" />
  
      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading">Oh yeah, its that good. <span className="text-muted">See for yourself.</span></h2>
          <p className="lead">Another featurette? Of course. More placeholder content here to give you an idea of how this layout would work with some actual real-world content in place.</p>
        </div>
        <div className="col-md-5 order-md-1">
        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/about/2.jpg"} alt="lorem ipsum" />
  
        </div>
      </div>
  
      <hr className="featurette-divider" />
  
      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading">And lastly, this one. <span className="text-muted">Checkmate.</span></h2>
          <p className="lead">And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
        </div>
        <div className="col-md-5">
        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/about/3.jpg"} alt="lorem ipsum" />
  
        </div>
      </div>
    </div>
        </>
    );
}
export default Body;