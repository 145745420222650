import React from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const ClientLogo = () => {
	 const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1
    };
	
	return(
	 <>
    <div className="container my-5 clientlogo">
    <div className="row text-center">
    <div className="col-md-12 my-3 text-center">
        <h2 data-aos="fade-up" className="title aos-init aos-animate">Our Clients</h2>
        <p data-aos="fade-up" className="subtitle aos-init aos-animate">It has survived not only five centuries, but also the leap into electronic typesetting</p>
    </div>
</div>
        <div className="row text-center" >
        <div className="col-md-12">
        <Slider {...settings}>
        <div className="card">
            <div className="card-body">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/clients/aws.jpg"} alt="logo name" />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/clients/cyberark.jpg"} alt="logo name" />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/clients/hcl.jpg"} alt="logo name" />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/clients/ibm.jpg"} alt="logo name" />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/clients/Matillion.jpg"} alt="logo name" />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/clients/Microsoft.jpg"} alt="logo name" />
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/clients/Spectrum-Enterorise.jpg"} alt="logo name" />
            </div>
          </div>
        </Slider>
        </div>
		</div>
	</div>
	  
</>
	);
}

export default ClientLogo;