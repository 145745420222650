import { Link } from "react-router-dom";
// function getCurrentYear() {
//   return new Date().getFullYear(); 
// };
// document.getElementById("year").innerHTML = getCurrentYear(); 

const Footer = () => {
    return(
    <>
   <footer className="mt-auto">
     <div className="container-fluid py-5">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <Link className="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" to="/" aria-label="Bootstrap">
                  <img className="img-fluid logo" src={process.env.PUBLIC_URL + "/assets/img/Northway-logo-final.png"} alt="logo" />
                </Link>
                <ul className="list-unstyled small text-muted">
                  <li className="mb-2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters... <Link to="/about">Know more</Link></li>
                </ul>
              </div>
              <div className="col-6 col-lg-2 offset-lg-1 mb-3">
                <h5>Links</h5>
                <ul className="list-unstyled">
                  <li className="mb-2"><Link to="/">Home</Link></li>
                  <li className="mb-2"><Link to="/docs/5.0/">Docs</Link></li>
                  <li className="mb-2"><Link to="/docs/5.0/examples/">Examples</Link></li>
                  <li className="mb-2"><Link to="https://themes.getbootstrap.com/">Themes</Link></li>
                  <li className="mb-2"><Link to="https://blog.getbootstrap.com/">Blog</Link></li>
                </ul>
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <h5>Guides</h5>
                <ul className="list-unstyled">
                  <li className="mb-2"><Link to="/docs/5.0/getting-started/">Getting started</Link></li>
                  <li className="mb-2"><Link to="/docs/5.0/examples/starter-template/">Starter template</Link></li>
                  <li className="mb-2"><Link to="/docs/5.0/getting-started/webpack/">Webpack</Link></li>
                  <li className="mb-2"><Link to="/docs/5.0/getting-started/parcel/">Parcel</Link></li>
                </ul>
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <h5>Projects</h5>
                <ul className="list-unstyled">
                  <li className="mb-2"><Link to="https://github.com/twbs/bootstrap">Bootstrap 5</Link></li>
                  <li className="mb-2"><Link to="https://github.com/twbs/bootstrap/tree/v4-dev">Bootstrap 4</Link></li>
                  <li className="mb-2"><Link to="https://github.com/twbs/icons">Icons</Link></li>
                  <li className="mb-2"><Link to="https://github.com/twbs/rfs">RFS</Link></li>
                  <li className="mb-2"><Link to="https://github.com/twbs/bootstrap-npm-starter">npm starter</Link></li>
                </ul>
              </div>
              <div className="col-6 col-lg-2 mb-3">
                <h5>Community</h5>
                <ul className="list-unstyled">
                  <li className="mb-2"><Link to="https://github.com/twbs/bootstrap/issues">Issues</Link></li>
                  <li className="mb-2"><Link to="https://github.com/twbs/bootstrap/discussions">Discussions</Link></li>
                  <li className="mb-2"><Link to="https://github.com/sponsors/twbs">Corporate sponsors</Link></li>
                  <li className="mb-2"><Link to="https://opencollective.com/bootstrap">Open Collective</Link></li>
                  <li className="mb-2"><Link to="https://bootstrap-slack.herokuapp.com/">Slack</Link></li>
                  <li className="mb-2"><Link to="https://stackoverflow.com/questions/tagged/bootstrap-5">Stack Overflow</Link></li>
                </ul>
              </div>
            </div>
      <p className="float-end"><Link to="#" id="onTOP">Back to top</Link></p>
      <p>© <span id="year"></span> Company, Inc. · <Link to="#">Privacy</Link> · <Link to="#">Terms</Link></p>
      </div>
    </footer>
    </>
    );
}
export default Footer;