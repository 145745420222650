import { Link } from "react-router-dom";
// import logo from 'process.env.PUBLIC_URL' + './assets/img/Northway-logo-final.png';

const Header = () => {
    return(
    <>
   <header className="header" id="header">
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-6">
            <ul className="social">
              <li><Link to="/"><img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/social/facebook.png"} alt="facebook" /></Link></li>
              <li><Link to="/"><img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/social/twitter.png"} alt="twitter" /></Link></li>
              <li><Link to="/"><img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/social/instagram.png"} alt="instagram" /></Link></li>
              <li><Link to="/"><img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/social/linkedin.png"} alt="linkedin" /></Link></li>
              <li><Link to="/"><img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/social/youtube.png"} alt="youtube" /></Link></li>
            </ul>
         </div>
         <div className="col-md-6">
         <ul className="social ml-auto">
         <li><Link to="mailto:info@northway.co.in"><img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/social/mail.png"} alt="Mail" /> <span>info@northway.co.in</span></Link></li>
              <li><Link to="tel:9899577740"><img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/social/whatsapp.png"} alt="Call or Whatsapp" /> <span>+91 9899577740</span></Link></li>
          </ul>
         </div>
       </div>
     </div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/"><img className="img-fluid logo" src={process.env.PUBLIC_URL + "/assets/img/Northway-logo-final.png"} alt="logo" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="me-auto"></ul>
            <ul className="navbar-nav mb-2 mb-lg-0" id="myDiv">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
</header>
    </>
    );
}
export default Header;