const Banner = () => {
    return(
        <>
       <div className="container-fluid banner">
           <div className="row">
               <div className="col-md-6 bg-theme m-auto">
                  <h1><span>Mobile App</span>
<span>Development - Step</span>
<span>by Step Guide for </span>
2022</h1>
<button className="btn btn-theme2">Click here to get Free Quote <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></button>
<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Delectus quam adipisci</p>
               </div>
               <div className="col-md-6">
                   <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/slider/banner1.jpg"} alt="banner" />
               </div>
           </div>
       </div>
        </>
    );
}
export default Banner;