import Body from "./Body";
import React from "react";
// import Slider from "./Slider";
import ClientLogo from "./ClientLogo";
import Portfolio from "./Portfolio";
import Banner from "./Banner";

const Home = () => {
    return(
        <>
        <Banner/>   
        {/* <Slider /> */}
        <Body />
        <Portfolio />
        <ClientLogo />
        </>
    );
}
export default Home;