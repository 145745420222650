import { Link } from 'react-router-dom';
const Portfolio = () => {
    return(
        <>
<div className="container mt-3 portfolio">
<div className="row text-center">
    <div className="col-md-12 my-3 text-center">
        <h2 data-aos="fade-up" className="title aos-init aos-animate">Portfolio</h2>
        <p data-aos="fade-up" className="subtitle aos-init aos-animate">It has survived not only five centuries, but also the leap into electronic typesetting</p>
    </div>
</div>
<ul className="nav nav-pills justify-content-center">
  <li className="nav-item">
    <Link className="nav-link active" data-bs-toggle="pill" to="#home">IT Projects</Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" data-bs-toggle="pill" to="#menu1">IT Infratech Projects</Link>
  </li>
  <li className="nav-item">
    <Link className="nav-link" data-bs-toggle="pill" to="#menu2">Goverment Projects</Link>
  </li>
</ul>

  <div className="tab-content">
    <div id="home" className="container tab-pane active"><br />
        <div className='row'>
          <div className='col-md-4'>
            <div className="card img-fluid">
             <img className="card-img-top" src={process.env.PUBLIC_URL + "/assets/img/portfolio/1.jpg"} alt="Card image" style={{width:'100%',}} />
             <div className="card-img-overlay">
                <h4 className="card-title">Projects Name 1</h4>
                {/* <p className="card-text">some example text.</p> */}
                <Link to="#" className="btn btn-primary">Check Website</Link>
             </div>
             </div>
          </div>
         
          <div className='col-md-4'>
            <div className="card img-fluid">
             <img className="card-img-top" src={process.env.PUBLIC_URL + "/assets/img/portfolio/2.jpg"} alt="Card image" style={{width:'100%',}} />
             <div className="card-img-overlay">
                <h4 className="card-title">Projects Name 1</h4>
                {/* <p className="card-text">some example text.</p> */}
                <Link to="#" className="btn btn-primary">Check Website</Link>
             </div>
             </div>
          </div>
        </div>
    </div>
    <div id="menu1" className="container tab-pane fade"><br />
    <div className='row'>
          <div className='col-md-4'>
            <div className="card img-fluid">
             <img className="card-img-top" src={process.env.PUBLIC_URL + "/assets/img/portfolio/3.jpg"} alt="Card image" style={{width:'100%',}} />
             <div className="card-img-overlay">
                <h4 className="card-title">Projects Name 1</h4>
                {/* <p className="card-text">some example text.</p> */}
                <Link to="#" className="btn btn-primary">Check Website</Link>
             </div>
             </div>
          </div>
        </div>
    </div>
    <div id="menu2" className="container tab-pane fade"><br />
    <div className='row'>
          <div className='col-md-4'>
            <div className="card img-fluid">
             <img className="card-img-top" src={process.env.PUBLIC_URL + "/assets/img/portfolio/4.jpg"} alt="Card image" style={{width:'100%',}} />
             <div className="card-img-overlay">
                <h4 className="card-title">Projects Name 1</h4>
                {/* <p className="card-text">some example text.</p> */}
                <Link to="#" className="btn btn-primary">Check Website</Link>
             </div>
             </div>
          </div>
        </div>
    </div>
    </div>
</div>
        </>
    );
}
export default Portfolio;