const About = ()=> {
    return(
        <>
        <div className="banners">
            <div className="content">
                <h1>About Us</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
            </div>
            <div className="image">
               <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/about.jpg"} alt="lorem ipsum" />
            </div>
        </div>
        <div className="container my-5">
        <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading">About Our Comapny <span className="text-muted">Comapny</span></h2>
          <p className="lead">Lorem Ipsum And yes, this is the last block of representative placeholder content. Again, not really intended to be actually read, simply here to give you a better view of what this would look like with some actual content. Your content.</p>
          <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  </p>
        </div>
        <div className="col-md-5">
        <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/about/3.jpg"} alt="lorem ipsum" />
  
        </div>
      </div>
        </div>
        </>
    );
}

export default About;