import AOS from "aos";
import "aos/dist/aos.css";
import{ init } from '@emailjs/browser';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
init("SCOGRKeFHsOoW8RA0");
const Contact = ()=> {
  // const [enterName, setEnterName] = useState("");
  // const [enterEmail, setEnterEmail] = useState("");
  // const [enterPhone, setEnterPhone] = useState("");
  // const [enterMessage, setEnterMessage] = useState("");
  // function nameChangeHandler(event) {
  //   setEnterName(event.target.value);
  // }
  // function emailChangeHandler(event) {
  //   setEnterEmail(event.target.value);
  // }
  // function phoneChangeHandler(event) {
  //   setEnterPhone(event.target.value);
  // }
  // function messageChangeHandler(event) {
  //   setEnterMessage(event.target.value);
  // }
  // let expensesData = {
  //   name: enterName,
  //   email: enterEmail,
  //   phone: enterPhone,
  //   message: enterMessage
  // };
  const form = useRef();
  const sendEmail = (e) => {
   // console.log(expensesData)
    e.preventDefault();

    emailjs.sendForm('service_vanxur9', 'template_69yt70c', form.current, 'SCOGRKeFHsOoW8RA0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      alert("Thanks for submitting the form");
      // setEnterName("");
      // setEnterEmail("");
      // setEnterPhone("");
      // setEnterMessage("");

  };
    AOS.init();
    return(
        <>
        <div className="banners">
            <div className="content">
                <h1>Contact Us</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
            </div>
            <div className="image">
               <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/contact.jpg"} alt="lorem ipsum" />
            </div>
        </div>
        <div className="container my-5">
        <div className="row g-4" data-aos="fade-in" >
        <div className="col-md-4 col-sm-6 text-center">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title title">USA Address</h5>
              <p className="card-text">600 N, BROADWAY ST STE 5 3520, MIDDLETOWN DE 19709</p>
              <h5 className="card-title title">India Address</h5>
              <p className="card-text"> A-48 3F, Sector-64, Noida - 201301, (UP) <br /> E-81, Sector-63, Noida - 201301, (UP)<br /> +91 9599454591</p>
              <h5 className="card-title title">Hong Kong Address</h5>
              <p className="card-text"> Suite 02, Level 12A, Enterprise Building 228 - 238, Queen's Road Central, Central, Hong Kong<br /> +91 9599454591</p>
            </div>
          </div>
        </div>
        <div className="col-md-8 col-sm-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title title">Feel Free to Query Us </h5>
                <form ref={form} onSubmit={sendEmail}>
                 <div className="form-group mt-2">
                       <label>Name</label>
                       <input placeholder="Name" className="form-control" id="name" type="text" name="name" />
                  </div>
                  <div className="form-group mt-2">
                    <label>Email</label>
                    <input placeholder="Email" className="form-control" id="email" type="email" name="email"  />
                  </div>
                  <div className="form-group mt-2">
                    <label>Phone: </label>
                    <input placeholder="Phone" className="form-control" id="phone" type="tel" name="phone"  />
                  </div>
                 <div className="form-group mt-2">
                    <label>Message</label>
                    <textarea placeholder="Something type here" className="form-control" id="message" name="message"></textarea>
                 </div>
                 <div className="form-group mt-2">
                 <input className="btn btn-primary" type="submit" value="Submit" />
                 </div>
               </form>
              </div>
            </div>
          </div>
      </div>
        </div>
        </>
    );
}

export default Contact;