import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Footer from './Pages/Footer';
import Header from './Pages/Header';
import Home from './Pages/Home';
import './Pages/main.css';
import AOS from "aos";
import "aos/dist/aos.css";


function App() {
  AOS.init();
  return (
    <>
    <Router>
    <Header />
    <Routes>
      <Route exact path ="/" element={<Home />}></Route>
      <Route exact path ="/about" element={<About />}></Route>
      <Route exact path ="/contact" element={<Contact />}></Route>
    </Routes>
    <Footer />
    </Router>
    
    </>
  );
}

export default App;
